.about__container {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    column-gap: 4rem;
}

.about__me {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(45deg, transparent, var(--color-bg-variant), transparent);
    display: grid;
    place-items: center;
}

.about__me-image {
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(10deg);
    transition: var(--transition);
}
.about__me-image:hover {
    transform: rotate(0);
}

.about__info {
    grid-template-columns: repeat(2, 250px);
    gap: 1.5rem;
    margin-bottom: var(--mb-2);
}

.about__box {
    background-color: var(--color-box);
    border: 1px solid rgba(0, 0, 0, 0.3);
    text-align: center;
    padding: 1rem 1.25rem;
    border-radius: 1rem;
}

.about__icon {
    font-size: 1.5rem;
    margin-bottom: var(--mb-0-5);
    color: var(--color-bg-dark);
}

.about__title {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    color: var(--color-bg-dark);
}

.about__subtitle {
    font-size: var(--small-font-size);
    color: var(--text-color);
}

.about__description {
    padding: 0;
    margin-bottom: var(--mb-2-5);
    color: var(--text-color);
}

/* BREAKPOINTS */
/* for large devices */
@media screen and (max-width: 992px){
    .about__container {
        grid-template-columns: 1fr;
        row-gap: 2.5rem;
    }
    .about__me {
        width: 50%;
        margin: 2rem auto 4rem;
    }

    .about__box {
        padding: 0.75rem 0.5rem;
    }
    .about__data {
        text-align: center;
    }
    .about__info {
        justify-content: center;
    }
    .about__description {
        padding: 0 4rem;
        margin-bottom: 2rem;
    }

}
  /* for medium devices */
    @media screen and (max-width: 576px){
        .about__me {
            width: 65%;
            margin: 0 auto 3rem;
        }
        .about__info {
            grid-template-columns: repeat(2, 1fr);
        }
        .about__description {
            padding: 0;
        }
  }
  
  /* for small devices */
  @media screen and (max-width: 350px){
    .about__info {
        grid-template-columns: repeat(2, 1fr);
    }
  }