.skills__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}
.skills__content {
    background: var(--color-box);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid rgba(0, 0, 0, 0.3);
    transition: var(--transition);
}

.skills__content:hover {
    background: transparent;
    border-color: var(--color-bg-variant);
    cursor: default;
}

.skills__title {
    font-size: var(--h2-font-size);
    font-weight: var(--font-medium);
    text-align: center;
    margin-bottom: var(--mb-1-5);
    color: var(--color-bg-variant);

}
.skills__box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
}
.skills__data {
    display: flex;
    gap: 2rem;

}
.skills .bx-badge-check {
    font-size: 1.25rem;
    color: var(--color-skills);
}
.skills__name {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    line-height: 18px;
    color: var(--color-bg-dark);
}
.skills__level {
    font-size: var(--smaller-font-size);
    color: var(--color-skills);
}


/* BREAKPOINTS */
/* for large devices */
@media screen and (max-width: 992px){
    .skills__container {
        grid-template-columns: max-content;
        row-gap: 2rem;
    }
    .skills__content {
        width: 80%;
        padding: 2rem;
        margin: 0 auto ;
    }
  }
  
  /* for medium devices */
  @media screen and (max-width: 768px){
    .skills__container {
        grid-template-columns: 1fr;
    }
    .skills__content {
        padding: 1.5rem;
    }
  }
  
  @media screen and (max-width: 576px){
    .skills__container {
        gap: 1rem;
    }
    .skills__content {
        width: 100%;
        padding: 2rem 1rem;
    }
  }
  
  /* for small devices */
  @media screen and (max-width: 350px){
    .skills__box {
        column-gap: 1.25rem;
    }
    .skills__name {
        font-size: var(--small-font-size);
    }
  }
