.header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--z-fixed);
    background-color: var(--color-bg);
}
.nav {
    height: calc(var(--header-height) + 1.5rem);
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 1rem;
}

.nav__logo {
    display: flex;
    align-items: center;
}
.nav__logo-img {
    width: 28px;
    height: auto;
    margin-right: 0.75rem;
}

.nav__logo,
.nav__toggle {
    color: var(--color-bg-dark);
    font-weight: var(--font-semi-bold);
    font-size: var(--h2-font-size);
}


/* DARK MODE CODES */

.dark__mode {
    margin-top: -30px;
    /* margin-left: 10px; */
}

.dark__mode-label {
    width: 65px;
    height: 30px;
    position: relative;
    display: block;
    background: #ebebeb;
    border-radius: 200px;
    box-shadow: inset 0px 5px 15px rgba(0, 0, 0, 0.4),
        inset 0px -5px 15px rgba(255, 255, 255, 0.4);
    cursor: pointer;
    transition: 0.3s;
}
.dark__mode-label:after {
    content: "";
    width: 25px;
    height: 25px;
    position: absolute;
    top: 3px;
    left: 3px;
    background: linear-gradient(180deg, #ffcc89, #d8860b);
    border-radius: 180px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
}
.dark__mode-input {
    width: 0;
    height: 0;
    visibility: hidden;
}
.dark__mode-input:checked + .dark_mode-label {
    background: #242424;
}
.dark__mode-input:checked + .dark__mode-label:after {
    left: 62px;
    transform: translateX(-100%);
    background: linear-gradient(180deg, #777, #3a3a3a);
}
.dark__mode-label:active:after {
    width: 30px;
}

.dark__mode-label svg {
    position: absolute;
    width: 20px;
    top: 5px;
    z-index: 100;
}
.dark__mode-label svg.sun {
    left: 5px;
    fill: #fff;
    transition: 0.3s;
}
.dark__mode-label svg.moon {
    left: 40px;
    fill: #7e7e7e;
    transition: 0.3s;
}
.dark__mode-input:checked + .dark_mode_label svg.sun {
    fill: #7e7e7e;
}
.dark__mode-input:checked + .dark_mode_label svg.moon {
    fill: #fff;
}

.nav__list {
    display: flex;
    column-gap: 2rem;
}
.float{
	position:fixed;
	width:50px;
	top:20px;
	right:40px;
}

.my-float{
	margin-top:22px;
}
.nav__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--color-bg-dark);
    font-size: var(--small-font-size);
    font-weight: var(--font-medium);
    transition: .3s;
}
.nav__icon,
.nav__close,
.nav__toggle {
    display: none;
}

/* ACTIVE LINKS */
.active-link,
.nav__link:hover {
    color: var(--color-bg-variant);
}


/* BREAKPOINTS */
/* for medium devices */
@media screen and (max-width: 768px){
    .header {
        top: initial;
        bottom: 0;
    }

    .nav {
         height: var(--header-height);
    }
    .nav__menu {
        position: fixed;
        bottom: -100%;
        left: 0;
        width: 100%;
        background-color: var(--color-bg);
        padding: 2rem 1.5rem 4rem;
        box-shadow: 0 -1px 4px rgba(0,0,0,0.15);
        transition: .3s;
    }

    /* SHOW MENU */
    .show-menu {
        bottom: 0;
    }

    .nav__list {
        display: grid;
        grid-template-columns: repeat(3,1fr);
        gap: 2rem;
    }

    .nav__icon {
        font-size: 1.2rem;
    }

    .nav__close {
        position: absolute;
        right: 1.3rem;
        bottom: .5rem;
        font-size: 1.5rem;
        cursor: pointer;
        color: var(--color-bg-dark);
    }

    .nav__close:hover {
        color: var(--color-bg-variant);
    }
    .nav__toggle {
        font-size: 1.1rem;
        cursor: pointer;
    }
    .nav__icon,
    .nav__close,
    .nav__toggle {
        display: block;
    }
}

/* for small devices */
@media screen and (max-width: 350px){
    .nav__menu {
        padding: 2rem 0.25rem 4rem;
    }

    .nav__list {
        column-gap: 0;
    }
}