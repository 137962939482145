/* Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

/* Variable CSS */
:root {
  --header-height: 3rem;

   /* Font and typography */
  --body-font: "Poppins", sans-serif;

  --big-font-size: 3.5rem;
  --h1-font-size: 2.25rem;
  --h2-font-size: 1.5rem;
  --h3-font-size: 1rem;
  --normal-font-size: 1rem;
  --small-font-size: 0.875rem;
  --smaller-font-size: 0.813rem;
  --tiny-font-size: 0.625rem;

  /* font weight */
  --font-normal: 400;
  --font-medium: 500;
  --font-semi-bold: 600;

  /* Margenes Bottom */
  --mb-0-25: 0.25rem;
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;
  --mb-3: 3rem;

  /* z index */
  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;

  --transition: all 400ms ease;


  --color-bg: #FAFAFA;
  --color-bg-variant: #333333;
  --color-skills: #757575;
  --title-color: #333333; 
  --color-bg-dark: #000;
  --color-box: #fff;
  --text-color: #757575;
}

[data__theme='dark'] {
  --color-bg: #1f1f38;
  --color-bg-variant: #3366ff;
  --color-skills: #3366ff;
  --color-bg-dark: #fff;
  --color-box: #2c2c6c;
}

/* Responsive typography */
@media screen and (max-width: 992px){
  :root {
    --big-font-size: 2.75rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1rem;
    --normal-font-size: 0.938rem;
    --small-font-size: 0.813rem;
    --smaller-font-size: 0.75rem;
  }
}

/* BASE */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
}

html {
  scroll-behavior: smooth;
}

body,
button,
input,
textarea {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}

body {
  background-color: var(--color-bg);
  line-height: 1.7;
  /* background-image: url(../src/assets/bg-texture.png); */
}

h1, h2, h3 {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
}

/* REUSABLE CSS CLASSES */
.section {
  padding: 6rem 0 2rem;
}
.section__title {
  font-size: var(--h1-font-size);
  color: var(--color-bg-variant);
}

.section__subtitle {
  display: block;
  font-size: var(--small-font-size);
  margin-bottom: 4rem;
  color: var(--text-color);
}

.section__title,
.section__subtitle {
  text-align: center;
}

/* LAYOUT */
.container {
  max-width: 968px;
  margin-right: auto;
  margin-left: auto;
}

.grid {
  display: grid;
  gap: 1.5rem;
}

/* BUTTONS */
a {
  color: var(--color-primary);
  transition: var(--transition);
}

a:hover {
  color: var(--white-color);
}

.btn {
  width: max-content;
  display: inline-block;
  color: var(--color-bg-variant);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-bg-variant);
  transition: var(--transition);
}

.btn:hover {
  background: var(--color-bg-variant);
  color: var(--color-bg);
}

.btn__primary {
  background: var(--color-bg-variant);
  color: var(--color-bg);
}
.btn__primary:hover {
  background: transparent;
  color: var(--color-bg-variant);
  border-color: var(--color-bg-variant);
}

/* BREAKPOINTS */
/* for large devices */
@media screen and (max-width: 992px){
  .container{
    margin-left: var(--mb-1-5);
    margin-right: var(--mb-1-5);
  }
  .button {
    padding: 1rem 1.75rem;
  }
  .button__icon {
    width: 22px;
    height: 22px;
  }
}

/* for medium devices */
@media screen and (max-width: 768px){
  body {
    margin: 0 0 var(--header-height) 0;
  }
  .section {
    padding: 2rem 0 4rem;
  }
  .section__subtitle {
    margin-bottom: var(--mb-3);
  }
}

@media screen and (max-width: 576px){

}

/* for small devices */
@media screen and (max-width: 350px){
  :root {
    --big-font-size: 2.25rem;
  }
  .container{
    margin-left: var(--mb-1);
    margin-right: var(--mb-1);
  }
}