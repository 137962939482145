.portfolio_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.portfolio__item {
  background: var(--color-box);
  padding: 1.3rem;
  border-radius: 2rem;
  border: 1px solid rgba(0, 0, 0, 0.3);
  transition: var(--transition);
}

.portfolio__item:hover {
  border-color: var(--color-bg-variant);
  background: transparent;
}

.portfolio__item-image {
  border-radius: 1.5rem;
  overflow: hidden;
}

.portfolio__project-title {
  margin: var(--mb-0-25);
  color: var(--color-bg-dark);
  text-align: center;
}

.portfolio__project-description {
    margin: var(--mb-0-25);
    color: var(--text-color);
    font-weight: var(--font-medium);
}

.portfolio__item-cta {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.portfolio__item-cta a {
  border-radius: 2rem;
}

/* MEDIA QUERIES (MEDIA DEVICES) */
@media screen  and (max-width: 1024px){
  .portfolio_container{
      grid-template-columns: 1fr 1fr;
      gap: 1.2rem;
  }
  
}

/* * MEDIA QUERIES (SMALL DEVICES) */
@media screen  and (max-width: 600px){
  .portfolio_container {
      grid-template-columns: 1fr;
      gap: 1rem;
  }
}